.custom-breadcrumb {
  background-color: #35597d;
  border-radius: 4px;
  margin: 0 0 20px;
  padding: 8px 15px;
}

.custom-breadcrumb ul {
  list-style: none;
}

.custom-breadcrumb li {
  display: inline;
}

.custom-breadcrumb-separator {
  color: #ccc;
}

.custom-breadcrumb .active {
  color: #999;
}

.error {
  border: 2px solid red;
  padding: 20px;
}

.ui-notification {
  text-align: center;
  vertical-align: middle;
}

.ui-notification > .message {
  font-weight: bold;
  font-size: 120%;
}

.required::after {
  content: " *";
  color: red;
}

.btn-group-divide > button {
  border-color: #2e4b6b;
}

.divide-default > button {
  border-color: #333;
}

.nested-check {
  list-style: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider::before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}

.btn-custom-default {
  background-color: #7b7d7d;
  border-color: #7b7d7d;
}

.action-buttons {
  padding-top: 5px;
  padding-bottom: 5px;
}

.report-block {
  display: table;
  width: 100%;
}

.report-block .left {
  display: table-cell;
  vertical-align: middle;
  width: 90%;
}

.report-block .right {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}

.letterlist ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.letterlist {
  list-style-type: none;
}

.letterlist li {
  float: left;
  margin: 10px;
}

.letterlist li a {
  margin: 0 4px;
  color: #eee;
  padding: 10px 20px;
  background: #3c66ad;
  font-size: 16px;
  font-weight: bold;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.attachcomplete p,
.attachcomplete td {
  color: #00bc8c;
}

.home-clicks li > a {
  cursor: pointer;
}

md-slider.md-default-theme.md-primary .md-sign,
md-slider.md-primary .md-sign {
  background-color: #298dff !important;
}

md-slider.md-default-theme.md-primary .md-thumb::after,
md-slider.md-primary .md-thumb::after {
  border-color: #298dff !important;
  background-color: #298dff !important;
}

md-slider.md-default-theme.md-primary .md-track.md-track-fill,
md-slider.md-primary .md-track.md-track-fill {
  background-color: #298dff !important;
}

md-slider.md-default-theme .md-track-ticks,
md-slider .md-track-ticks {
  color: #298dff !important;
}

md-slider.md-default-theme.md-primary .md-sign::after,
md-slider.md-primary .md-sign::after {
  border-top-color: #298dff !important;
}

.tablesort {
  cursor: pointer;
}

.modal-custom-lg {
  width: 100%;
}

.trial-stages-danger {
  background-color: #d9534f !important;
}

.trial-stages-success {
  background-color: #5cb85c !important;
}

.trial-stages-warning {
  background-color: #f0ad4e !important;
}
